class JobModel {
    payload;

    constructor(payload) {
        this.payload = payload;
    }

    fromResponseToEdit() {
        let { id, acnJobScope, paymentType, rates, remark } = this.payload;

        return {
            jobId: id,
            jobScopeId: acnJobScope.id,
            paymentType: paymentType,
            rates: rates,
            remark: remark
        };
    }

    toCreatePayload() {
        let { jobScopeId, paymentType, rates, remark } = this.payload;

        return {
            jobScopeId: jobScopeId || "",
            paymentType: paymentType || "Commission",
            rates: rates || "0",
            remark: remark || ""
        };
    }
    toEditPayload() {
        let { paymentType, rates, remark } = this.payload;

        return {
            paymentType: paymentType || "Commission",
            rates: rates || "0",
            remark: remark || ""
        };
    }
}

export default JobModel;
