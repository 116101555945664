<template>
  <div class="agent-collaboration-network-details">
    <div class="container fluid">
      <div class="my-4 mx-2">
        <fd-button
          class="bordered main mb-3"
          icon="fas fa-chevron-left"
          @click="$router.push({ name: 'ACN' })"
        >
          ACN List
        </fd-button>
        <h1>ACN Group</h1>
      </div>
      <div class="card p-2 mb-2">
        <div
          class="d-flex justify-content-between cursor-pointer"
          @click="toProperty(_.get(acn, 'acnQuest.listing.property'))"
        >
          <property-summary
            :isLoading="isGroupLoading"
            :property="_.get(acn, 'acnQuest.listing.property', {})"
            address
            :purpose="false"
          ></property-summary>
          <div class="d-flex align-items-center p-3">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
        <div
          v-if="isSpecialist || isRegistrant"
          class="d-flex justify-content-end mt-2"
        >
          <fd-button icon="fas fa-pen" @click="openEditPropertyModal">
            Edit Property
          </fd-button>
        </div>
      </div>

      <div
        class="card d-flex justify-content-between align-items-center p-3 mb-2"
      >
        <div class="row w-100">
          <display-data
            class="col-12 md-col-6"
            :isLoading="isGroupLoading"
            label="Listing Commission"
            :content="listingCommission"
          ></display-data>
          <display-data
            class="col-12 md-col-6"
            :isLoading="isGroupLoading"
            label="Estimated Amount"
            :content="groupShareAmount"
          ></display-data>
        </div>
        <div v-if="isSpecialist">
          <fd-button class="bordered main" @click="openEditCommissionModal">
            Edit
          </fd-button>
        </div>
      </div>

      <div class="jobs card p-2 mb-2">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="m-2 mb-3">Jobs</h3>
          <div v-if="isSpecialist">
            <fd-button
              class="bordered main"
              icon="fas fa-plus"
              @click="openJobModal('create')"
            >
              Add Jobs
            </fd-button>
          </div>
        </div>
        <!-- Loading State -->
        <div
          v-if="isJobLoading"
          class="d-flex justify-content-center py-4"
          :style="{ height: '250px' }"
        >
          <spinner></spinner>
        </div>
        <!-- Content -->
        <div v-else>
          <!-- Fixed Roles -->
          <div
            class="bordered-card p-1"
            :style="{ display: 'grid', gap: '8px' }"
          >
            <JobListItem
              title="Registrant"
              :user="_.get(registrantJob, 'user', {})"
              :commission="`${_.get(registrantJob, 'rates')}%`"
              :estimatedAmount="getEstimatedAmount(registrantJob)"
              @click="openJobDetailModal(registrantJob)"
            >
              <div class="d-flex justify-content-end mt-2">
                <fd-button
                  v-if="isRegistrant && !hasSpecialist"
                  class="bordered main"
                  @click.stop="openEditRegistrantModal"
                >
                  Edit
                </fd-button>
              </div>
            </JobListItem>
            <JobListItem
              title="Specialist"
              :user="_.get(specialistJob, 'user', {})"
              :commission="`${_.get(specialistJob, 'rates')}%`"
              :estimatedAmount="getEstimatedAmount(specialistJob)"
              @click="openJobDetailModal(specialistJob)"
            ></JobListItem>
            <JobListItem
              title="Closer"
              :user="_.get(closerJob, 'user', {})"
              :commission="`${_.get(closerJob, 'rates')}%`"
              :estimatedAmount="getEstimatedAmount(closerJob)"
              @click="openJobDetailModal(closerJob)"
            >
              <div class="d-flex justify-content-end mt-2">
                <fd-button
                  v-if="isSpecialist"
                  class="bordered main"
                  @click.stop="openEditCloserModal"
                >
                  Edit
                </fd-button>
              </div>
            </JobListItem>
          </div>
          <!-- Agent Jobs -->
          <div class="p-1 mt-2">
            <div v-for="job in jobs" :key="job.id">
              <JobListItem
                v-if="!isMainPositions(job)"
                :title="_.get(job, 'acnJobScope.title')"
                :user="_.get(job, 'user', {})"
                :commission="
                  getCommission(job, Number(_.get(specialistJob, 'rates')))
                "
                :estimatedAmount="getEstimatedAmount(job,true)"
                @click="openJobDetailModal(job)"
              >
                <div class="d-flex justify-content-end mt-2">
                  <fd-button
                    v-if="isSpecialist && !job.isCompleted"
                    class="bordered main"
                    @click.stop="openJobModal('edit', job)"
                  >
                    Edit
                  </fd-button>
                </div>
              </JobListItem>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isRegistrant" class="card d-flex justify-content-end p-2">
        <fd-button class="danger" @click="closeGroup">Close Group</fd-button>
      </div>
    </div>
    <!-- ============================== Modal ============================== -->
    <modal v-model="editRegistrantModal.isActive">
      <div class="card p-2">
        <h3 class="mb-4">Edit Registrant Rate</h3>

        <banner class="bg-orange-100 fg-grey-800 p-2 mb-1" :dismissable="false">
          Registrant rate will not be able to modified after Specialist is
          assigned
        </banner>

        <AdjustRegistrantRate
          :property="_.get(acn, 'acnQuest.listing.property')"
          :payload="editRegistrantModal.payload"
          submitButtonLabel="Save"
          @submit="updateRegistrantRate"
          @close="editRegistrantModal.isActive = false"
        ></AdjustRegistrantRate>
      </div>
    </modal>
    <modal v-model="editCloserModal.isActive">
      <div class="card p-2 md-p-3">
        <h3 class="mb-4">Edit Closer Rate</h3>

        <AdjustCloserRate
          :property="_.get(acn, 'acnQuest.listing.property')"
          :payload="editCloserModal.payload"
          :specialistRate="Number(_.get(specialistJob, 'rates'))"
          submitButtonLabel="Save"
          @submit="updateCloserRate"
          @close="editCloserModal.isActive = false"
        ></AdjustCloserRate>
      </div>
    </modal>

    <!-- Job -->
    <modal v-model="jobDetailModal.isActive">
      <JobDetail
        class="card job-detail"
        :job="jobDetailModal.job"
        :applications="jobDetailModal.applications"
        :isApplicationsLoading="jobDetailModal.isApplicationsLoading"
        :isRegistrant="isRegistrant"
        :isSpecialist="isSpecialist"
        :specialistCommission="_.get(specialistJob, 'rates')"
        :listingCommission="_.get(acn, 'commissionRate')"
        :listing="_.get(acn, 'acnQuest.listing')"
        @becomeSpecialist="becomeSpecialist"
        @approveApplication="approveApplication"
        @rejectApplication="rejectApplication"
        @deleteJob="deleteJob"
        @editJob="
          (job) => {
            jobDetailModal.isActive = false;
            openJobModal('edit', job);
          }
        "
        @completeJob="openCompleteJobModal"
        @removeHandler="removeJobHandler"
      >
        <template #assign-closer>
          <div class="bordered-card p-2">
            <h5 class="mb-3">Assign Closer</h5>
            <fd-input
              v-model="closerAssign.search"
              label="Search Agent"
              class="w-100"
              name="acnAssignCloserSearch"
              type="text"
              placeholder="Enter agent name"
              @input="searchCloserAgent"
            ></fd-input>

            <hr class="my-2" />
            <div :style="{ maxHeight: '500px', overflowY: 'auto' }">
              <p v-if="closerAssign.isAgentsLoading" class="text-center">
                Loading...
              </p>
              <div
                v-else-if="closerAssign.agents.length < 1"
                class="text-center"
              >
                <p v-if="closerAssign.search == ''">Search agent name</p>
                <p v-else>No result</p>
              </div>
              <div v-for="agent in closerAssign.agents" :key="agent.id">
                <div class="d-flex justify-content-between align-items-center">
                  <JobHandler
                    :user="agent"
                    :getAgent="(val) => val"
                    :getUser="(val) => val.user"
                  ></JobHandler>
                  <div>
                    <fd-button
                      class="bordered main"
                      @click="assignCloser(agent)"
                    >
                      Assign
                    </fd-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </JobDetail>
    </modal>
    <!-- Add/Edit Job Modal -->
    <modal v-model="editCommissionModal.isActive">
      <EditCommission
        class="card"
        :payload.sync="editCommissionModal.payload"
        @submit="updateListingCommission"
      ></EditCommission>
    </modal>
    <!-- Add/Edit Job Modal -->
    <modal v-model="jobModal.isActive" persistent>
      <div class="card">
        <h3 class="p-3">{{ $toTitleCase(jobModal.mode) }} Job</h3>
        <JobForm
          :jobScopes="jobModal.jobScopes"
          :mode="jobModal.mode"
          :payload.sync="jobModal.payload"
          :specialistRates="_.get(specialistJob, 'rates')"
          :listing="_.get(acn, 'acnQuest.listing')"
          :listingCommission="_.get(acn, 'commissionRate')"
          :maxAllocationRates="_.get(specialistJob, 'maxAllocationRates')"
          :style="{ minWidth: '300px', maxWidth: '500px' }"
          @create="createJob"
          @update="updateJob"
          @close="jobModal.isActive = false"
          @submit="updateListingCommission"
        ></JobForm>
      </div>
    </modal>
    <!-- Complete Job Modal -->
    <modal v-model="completeJobModal.isActive">
      <div class="card">
        <h3 class="p-3">Complete Job</h3>
        <CompleteJob
          class="p-3"
          :payload.sync="completeJobModal.payload"
          :job="completeJobModal.job"
          @close="completeJobModal.isActive = false"
          @submit="completeJob"
        ></CompleteJob>
      </div>
    </modal>
    <!-- Edit Property Modal -->
    <modal v-model="editPropertyModal.isActive" fullscreen>
      <modal-content>
        <template #header>
          <div
            class="d-flex justify-content-between align-items-center bg-white p-2"
          >
            <h2>Edit Property</h2>
            <fd-button
              class="circle lg"
              @click="editPropertyModal.isActive = false"
            >
              <i class="fas fa-close"></i>
            </fd-button>
          </div>
        </template>
        <template #body>
          <div v-if="editPropertyModal.isLoading" class="text-center">
            Initializing Data...
          </div>

          <div v-else class="bg-grey-100">
            <div class="container fluid">
              <PropertyForm
                :form.sync="editPropertyModal.property"
                @submit="updateProperty"
              ></PropertyForm>
            </div>
          </div>
        </template>
      </modal-content>
    </modal>
  </div>
</template>

<script>
import { acn as acnAPI, property as propertyAPI } from "@/api";
import { PropertyModel } from "@/models";
import { acnPaymentType } from "@/enums";
import { numberWithCommas } from "@/utils/string";
import { convertNullIfEmpty as convNull } from "@/utils/null-check";
import { debounce } from "lodash";
import JobModel from "@/modules/ACN/models/JobModel";
import useACNRates from "@/modules/ACN/composables/useACNRates";
import Big from "big.js";

export default {
  setup() {
    const { getCommission, getJobEstimatedAmount } = useACNRates();
    return { getCommission, getJobEstimatedAmount };
  },
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary"),
    PropertyForm: () => import("@/modules/Property/components/PropertyForm"),
    JobListItem: () => import("@/modules/ACN/components/JobListItem"),
    AdjustRegistrantRate: () =>
      import("@/modules/ACN/components/AdjustRegistrantRate"),
    AdjustCloserRate: () => import("@/modules/ACN/components/AdjustCloserRate"),
    JobDetail: () => import("@/modules/ACN/components/JobDetail"),
    JobForm: () => import("@/modules/ACN/components/JobForm"),
    EditCommission: () => import("@/modules/ACN/components/EditCommission"),
    CompleteJob: () => import("@/modules/ACN/components/CompleteJob"),
    JobHandler: () => import("@/modules/ACN/components/JobHandler"),
    // Global Components
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    Banner: () => import("@/components/GlobalComponents/Banner")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      acn: {},
      jobs: [],
      jobsPagination: [],

      isGroupLoading: false,
      isJobLoading: true,

      closerAssign: {
        search: "",
        agents: [],
        isAgentsLoading: false,
        selected: ""
      },

      editCommissionModal: {
        isActive: false,
        payload: {
          commissionRate: 0
        }
      },
      editRegistrantModal: {
        isActive: false,
        payload: {
          rates: 0
        }
      },
      editCloserModal: {
        isActive: false,
        payload: {
          rates: 0
        }
      },
      completeJobModal: {
        isActive: false,
        payload: {
          remark: ""
        }
      },
      editPropertyModal: {
        isActive: false,
        isLoading: false,
        property: {}
      },
      jobDetailModal: {
        isActive: false,
        job: {},
        applications: [],
        isApplicationsLoading: false
      },
      jobModal: {
        mode: "create",
        isActive: false,
        jobScopes: [],
        payload: {
          jobScopeId: "",
          paymentType: "Commission",
          rates: "0",
          remark: ""
        },
        isJobScopeLoading: false
      },

      convNull
    };
  },
  computed: {
    registrantJob() {
      return this.jobs.find((job) => job.isForRegistrant);
    },
    specialistJob() {
      return this.jobs.find((job) => job.isForSpecialist);
    },
    closerJob() {
      return this.jobs.find((job) => job.isForCloser);
    },
    isRegistrant() {
      return this.registrantJob?.user?.id == this.$auth.user().id;
    },
    isSpecialist() {
      return this.specialistJob?.user?.id == this.$auth.user().id;
    },
    isCloser(){
	return this.closerJob?.user?.id == this.$auth.user().id;
},
    hasSpecialist() {
      return !!this.specialistJob.user;
    },
    listingCommission() {
      return this.acn.commissionRate != 0
        ? `${this.acn.commissionRate}%`
        : "Commission is not set";
    },
    groupShareAmount() {
      if (this.acn.commissionRate != 0) {
        let rate = new Big(this.acn.commissionRate).div(100);
        let price = this.acn.acnQuest?.listing?.property?.salePrice;
        return `RM${numberWithCommas(new Big(price).times(rate).toNumber())}`;
      } else {
        return "Commission is not set";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getACNGroup();
      this.getJobs();
    },
    isMainPositions(job) {
      return job.isForRegistrant || job.isForSpecialist || job.isForCloser;
    },
    toProperty(property) {
      this.$router.push({
        name: "ManagePropertyDetail",
        params: { id: property.id }
      });
    },
    async openEditPropertyModal() {
      await this.getPropertyDetails(this.acn.acnQuest.listing.property.id);
      this.editPropertyModal.isActive = true;
    },
    openEditCommissionModal() {
      this.editCommissionModal.isActive = true;
      this.editCommissionModal.payload = {
        commissionRate: this.acn.commissionRate
      };
    },
    openEditRegistrantModal() {
      this.editRegistrantModal.isActive = true;
      this.editRegistrantModal.payload.registrantRate = this.registrantJob.rates;
    },
    openEditCloserModal() {
      this.editCloserModal.isActive = true;
      this.editCloserModal.payload.rates = this.closerJob.rates;
    },
    openJobDetailModal(job) {
      this.jobDetailModal.isActive = true;
      this.jobDetailModal.job = job;
      this.getJobApplications(job);
    },
    closeJobDetailModal() {
      this.jobDetailModal.isActive = false;
    },
    openJobModal(mode, job = null) {
      this.$nextTick(() => {
        this.jobModal.mode = mode;
      });
      if (mode == "create") {
        this.resetJobModalPayload();
      }
      if (job) {
        this.jobModal.payload = new JobModel(job).fromResponseToEdit();
      }
      this.jobModal.isActive = true;
      this.getJobScopes();
    },
    openCompleteJobModal(job) {
      this.completeJobModal.isActive = true;
      this.completeJobModal.job = job;
    },
    getApplicationJobName(application) {
      if (application.acnReward.isForSpecialist) {
        return "Specialist";
      } else {
        return application.acnReward.acnJobScope.title;
      }
    },
    resetJobModalPayload() {
      this.jobModal.payload = {
        jobScopeId: "",
        paymentType: "Commission",
        rates: "0",
        remark: ""
      };
    },
    getEstimatedAmount(job) {
      if (this.isJobPaidWithCommission(job)) {
        let estimatedAmount = this.getJobEstimatedAmount({
          listingCommission: Number(this.acn.commissionRate),
          specialistCommission: Number(this.specialistJob?.rates),
          jobRates: Number(job.rates),
          salePrice: Number(this.acn.acnQuest?.listing?.property?.salePrice)
        });

        return `${estimatedAmount}`;
      }
      return undefined;
    },
    isJobPaidWithCommission(job) {
      return job.paymentType == acnPaymentType.COMMISSION;
    },

    // ============================== API RELATED ==============================
    async getACNGroup() {
      try {
        this.isGroupLoading = true;
        let data = await acnAPI.getGroup(this.$route.params.id);
        this.acn = this._.cloneDeep(data);
        this.isGroupLoading = false;
      } catch (error) {
        this.isGroupLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch ACN data. Please try again later."
        });
      }
    },
    async getJobs() {
      try {
        this.isJobLoading = true;
        let res = await acnAPI.getGroupJobs(this.$route.params.id);
        this.jobs = this._.cloneDeep(res.data);

        this.isJobLoading = false;
      } catch (error) {
        this.isJobLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch ACN jobs. Please try again later."
        });
      }
    },
    async getJobScopes() {
      try {
        if (this.jobModal.jobScopes.length > 0) return;

        this.jobModal.isJobScopeLoading = true;
        let data = await acnAPI.getJobScopes();
        this.jobModal.jobScopes = data.data;
        this.jobModal.isJobScopeLoading = false;
      } catch (error) {
        this.jobModal.isJobScopeLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch job scopes. Please try again later."
        });
      }
    },
    async getJobApplications(job) {
      try {
        this.jobDetailModal.isApplicationsLoading = true;
        let res = await acnAPI.getJobApplications(job.id);
        this.jobDetailModal.applications = this._.cloneDeep(res.data);

        this.jobDetailModal.isApplicationsLoading = false;
      } catch (error) {
        this.jobDetailModal.isApplicationsLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch job's applications. Please try again later."
        });
      }
    },
    async getPropertyDetails(id) {
      try {
        this.editPropertyModal.isLoading = true;
        let res = await propertyAPI.getProperty(id);
        this.editPropertyModal.property = PropertyModel.getToEditResponse(res);
        this.editPropertyModal.applications = this._.cloneDeep(res.data);

        this.editPropertyModal.isLoading = false;
      } catch (error) {
        this.editPropertyModal.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to init property details to edit. Please try again later."
        });
      }
    },
    async updateRegistrantRate(payload) {
      try {
        this.$store.commit("setIsLoading", true);

        let { registrantRate } = payload;
        let req = new JobModel({ rates: registrantRate }).toEditPayload();

        await acnAPI.updateJob(this.registrantJob.id, req);
        this.$store.commit("setIsLoading", false);
        this.editRegistrantModal.isActive = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Registrant rate has been updated successfully"
        });

        this.getJobs();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update registrant rate. Please try again later."
        });
      }
    },
    async updateProperty(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await propertyAPI.updateProperty(
          this.acn.acnQuest.listing.property.id,
          PropertyModel.postPayload(payload)
        );

        this.$store.commit("setIsLoading", false);
        this.editPropertyModal.isActive = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Property has been updated successfully."
        });
        this.getACNGroup();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update property data. Please try again later."
        });
      }
    },
    async updateCloserRate(payload) {
      try {
        this.$store.commit("setIsLoading", true);

        let req = new JobModel(payload).toEditPayload();

        await acnAPI.updateJob(this.closerJob.id, req);
        this.$store.commit("setIsLoading", false);
        this.editCloserModal.isActive = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Closer rate has been updated successfully"
        });

        this.getJobs();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update closer rate. Please try again later."
        });
      }
    },
    async becomeSpecialist() {
      let confirm = await this.$confirm({
        title: "Assign Myself As Specialist",
        message: `<p>Are you sure you want to assign yourself to be the specialist? </p>
          <p>You cannot change specialist afterwards unless you close the ACN Group.</p>`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.assignSelfAsSpecialist(this.acn.id);

          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "You have assigned yourself to be specialist."
          });
          this.closeJobDetailModal();
          this.getJobs();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to become specialist. Please try again later."
          });
        }
      }
    },
    searchCloserAgent: debounce(async function (val) {
      try {
        this.closerAssign.isAgentsLoading = true;
        let res = await acnAPI.getAgencyAgents(this.$auth.user().agency?.id, {
          queries: { "name[partial]": val }
        });
        this.closerAssign.agents = this._.cloneDeep(res.data);
        this.closerAssign.isAgentsLoading = false;
      } catch (error) {
        this.closerAssign.isAgentsLoading = false;
        console.log(error);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Search Closer Agent",
          text:
            "Something went wrong when trying to search agent for closer. Please try again later."
        });
      }
    }, 400),
    async assignCloser(agent) {
      let confirm = await this.$confirm({
        title: "Assign Closer",
        message: `Confirm assign ${agent.name} as closer?`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.assignCloser(this.closerJob.id, {
            userId: agent.user.id
          });

          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "You have assigned yourself to be specialist."
          });
          this.closeJobDetailModal();
          this.getJobs();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to become specialist. Please try again later."
          });
        }
      }
    },
    async approveApplication(application) {
      let applicationName = this.getApplicationJobName(application);
      let applicationAgentName = application.user.agentProfile.name;

      let confirm = await this.$confirm({
        title: "Approve Application",
        message: `Are you sure you want to approve ${applicationAgentName}'s application for ${applicationName}?`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.approveJobApplication(application.id);
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `You have approved ${applicationAgentName} for ${applicationName}`
          });

          this.getJobApplications(application.acnReward);
          this.jobDetailModal.isActive = false;
          this.getJobs();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to approve application. Please try again later."
          });
        }
      }
    },

    async rejectApplication(application) {
      let applicationName = this.getApplicationJobName(application);
      let applicationAgentName = application.user.agentProfile.name;

      let confirm = await this.$confirm({
        type: "alert",
        title: "Reject Application",
        message: `Are you sure you want to reject ${applicationAgentName}'s application for ${applicationName}?`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.rejectJobApplication(application.id);
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `${applicationAgentName}'s application has been rejected.`
          });

          this.getJobApplications(application.acnReward);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to reject application. Please try again later."
          });
        }
      }
    },

    async createJob(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        let req = new JobModel(payload).toCreatePayload();
        await acnAPI.createJob(this.acn.id, req);
        this.$store.commit("setIsLoading", false);
        this.jobModal.isActive = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Job Created",
          text: "New job position has been created successfully."
        });

        this.resetJobModalPayload();

        this.getJobs();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create new job for the ACN. Please try again later."
        });
      }
    },
    async updateJob(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        let req = new JobModel(payload).toEditPayload();
        await acnAPI.updateJob(payload.jobId, req);
        this.$store.commit("setIsLoading", false);
        this.jobModal.isActive = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Job Created",
          text: "Job position has been updated successfully."
        });

        this.resetJobModalPayload();

        this.getJobs();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update job for the ACN. Please try again later."
        });
      }
    },
    async deleteJob(jobId) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Job",
        message: "Are you sure you want to delete this job?",
        confirmText: "Delete"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.removeJob(jobId);
          this.$store.commit("setIsLoading", false);
          this.jobDetailModal.isActive = false;
          this.$notify({
            group: "alert",
            type: "success",
            title: "Job Deleted",
            text: "Job has been deleted successfully."
          });

          this.getJobs();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete this job. Please try again later."
          });
        }
      }
    },
    async removeJobHandler(job) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Remove Job Handler",
        message: `Are you sure you want to remove job handler for job ${job.acnJobScope.title}?`,
        confirmText: "Remove"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.withdrawFromJob(job.id);
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Remove Handler",
            text: "Job handler has been removed."
          });
          this.jobDetailModal.isActive = false;
          this.getJobs();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to remove job handler. Please try again later."
          });
        }
      }
    },
    async completeJob(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await acnAPI.markJobAsComplete(this.completeJobModal.job.id, payload);
        this.$store.commit("setIsLoading", false);
        this.jobDetailModal.isActive = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Job Complete",
          text: "Job has been marked as completed."
        });

        this.completeJobModal.isActive = false;
        this.getJobs();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to mark job as completed. Please try again later."
        });
      }
    },
    async updateListingCommission(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await acnAPI.updateListingCommission(this.acn.id, payload);
        this.editCommissionModal.isActive = false;
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Updated listing commission successfully."
        });

        this.getACNGroup();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update listing commission. Please try again later."
        });
      }
    },
    async closeGroup() {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Close ACN Group",
        message: `Are you sure you want to close this ACN Group? This action cannot be undone.`,
        confirmText: "Close Group"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.closeGroup(this.acn.id);
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "ACN Group has been closed successfully."
          });

          this.$router.push({ name: "ACN" });
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to close ACN Group. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.agent-collaboration-network-details {
  .job-detail {
    @media #{$breakpoint-up-sm} {
      min-width: 400px;
    }
    @media #{$breakpoint-up-md} {
      min-width: 600px;
    }
  }
}
</style>
